// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// LINK FIXED
$(document).ready(function() {
    var box = $('.link-fixed');
    var placeholder = $('.link-placeholder');
    var offset = box.offset().top; // Calculate the offset

    $(window).scroll(function() {
      if ($(window).scrollTop() >= offset) {
        box.addClass('fixed');
        placeholder.show();
      } else {
        box.removeClass('fixed');
        placeholder.hide();
      }
    });
  });

  // ANCRE
  $('a[href^="#"]').not(".nav-link").on('click',function (e) {
      console.log('link-#');
      e.preventDefault();

      var target = this.hash,
      $target = $(target);

      $('html, body').stop().animate({
          'scrollTop': $target.offset().top-140
      }, 1200, 'swing', function () {
          //window.location.hash = target;
      });
  });

$(document).ready(function(){
  $('.slideshow .slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
    arrows: false,
  });

  $('.testimonials .content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });
});
